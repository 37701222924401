<template lang="pug">
svg(
  style='position: absolute; width: 0; height: 0',
  width='0',
  height='0',
  version='1.1',
  xmlns='http://www.w3.org/2000/svg',
  xlink='http://www.w3.org/1999/xlink'
): defs
  symbol#svg-li: include ../svg/li.svg
  symbol#svg-ig: include ../svg/ig.svg
  symbol#svg-tiktok: include ../svg/tiktok.svg
  symbol#svg-email: include ../svg/email.svg
  symbol#svg-tick: include ../svg/tick.svg
  symbol#svg-logo: include ../svg/logo.svg
  symbol#svg-location: include ../svg/location.svg
  symbol#svg-burger: include ../svg/burger.svg
  symbol#svg-arrow: include ../svg/arrow.svg
  symbol#svg-bed: include ../svg/bed.svg
  symbol#svg-bath: include ../svg/bath.svg
  symbol#svg-arrow2: include ../svg/arrow2.svg
  symbol#svg-tag: include ../svg/tag.svg
  symbol#svg-filter-arrow: include ../svg/filter-arrow.svg
  symbol#svg-tip: include ../svg/tip.svg
  symbol#svg-calendar: include ../svg/calendar.svg
  symbol#svg-phone: include ../svg/phone.svg
  symbol#svg-plus: include ../svg/plus.svg
  symbol#svg-minus: include ../svg/minus.svg
  symbol#svg-close: include ../svg/close.svg
  symbol#svg-minus2: include ../svg/minus2.svg
  symbol#svg-plus2: include ../svg/plus2.svg
  symbol#svg-group: include ../svg/group.svg
  symbol#svg-arrow-circle: include ../svg/arrow-circle.svg
  symbol#svg-edit: include ../svg/edit.svg
  symbol#svg-map-bath: include ../svg/map/bath.svg
  symbol#svg-map-bed: include ../svg/map/bed.svg
  symbol#svg-map-close: include ../svg/map/close-icon.svg
  symbol#svg-map-mbath: include ../svg/map/mini-baths.svg
  symbol#svg-map-mbed: include ../svg/map/mini-beds.svg
  symbol#svg-map-mclose: include ../svg/map/mini-close-icon.svg
  symbol#svg-map-red: include ../svg/map/redleftarrow.svg
  symbol#svg-map-sred: include ../svg/map/small-red-right-arrow.svg
  symbol#svg-map-back: include ../svg/map/back.svg
</template>

<script>
  export default {};
</script>

<style lang="scss">
  .svg-li {
    width: 24px;
    height: 24px;
  }
  .svg-ig {
    width: 30px;
    height: 30px;
  }
  .svg-tiktok {
    width: 30px;
    height: 30px;
  }
  .svg-email {
    width: 26px;
    height: 24px;
  }
  .svg-logo {
    width: 138px;
    height: 72px;
  }
  .svg-location {
    width: 27px;
    height: 25px;
  }
  .svg-burger {
    width: 36px;
    height: 36px;
  }
  .svg-luxury {
    width: 158px;
    height: 56px;
  }
  .svg-arrow {
    width: 30px;
    height: 30px;
  }
  .svg-bed {
    width: 26px;
    height: 25px;
  }
  .svg-bath {
    width: 28px;
    height: 25px;
  }
  .svg-arrow2 {
    width: 11px;
    height: 22px;
  }
  .svg-tag {
    width: 20px;
    height: 20px;
  }
  .svg-filter-arrow {
    width: 15px;
    height: 15px;
  }
  .svg-tip {
    width: 15px;
    height: 14px;
  }
  .svg-calendar {
    width: 20px;
    height: 20px;
  }
  .svg-phone {
    width: 25px;
    height: 25px;
  }
  .svg-plus {
    width: 20px;
    height: 20px;
  }
  .svg-minus {
    width: 20px;
    height: 20px;
  }
  .svg-close {
    width: 36px;
    height: 36px;
  }
  .svg-minus2 {
    width: 26px;
    height: 25px;
  }
  .svg-plus2 {
    width: 26px;
    height: 25px;
  }
  .svg-group {
    width: 23px;
    height: 20px;
  }
  .svg-arrow-circle {
    width: 37px;
    height: 36px;
  }
  .svg-edit {
    width: 16px;
    height: 16px;
  }

  .svg-map-bath {
    width: 25px;
    height: 25px;
  }
  .svg-map-bed {
    width: 25px;
    height: 18px;
  }
  .svg-map-close {
    width: 36px;
    height: 36px;
  }
  .svg-map-mbath {
    width: 16px;
    height: 14px;
  }
  .svg-map-mbed {
    width: 16px;
    height: 12px;
  }
  .svg-map-mclose {
    width: 16px;
    height: 16px;
  }
  .svg-map-red {
    width: 30px;
    height: 20px;
  }
  .svg-map-sred {
    width: 16px;
    height: 12px;
  }
  .svg-map-back {
    width: 30px;
    height: 20px;
  }
</style>
