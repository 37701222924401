<template lang="pug">
footer.footer
  section.footerBackground
  section.footerContainer
    Social.footerSocial
    div © 2023 Ian Hurdle
    router-link.footer-privacyLink(:to='{ name: "PrivacyPage" }') Privacy Policy
</template>

<script>
  export default {
    components: {
      Social: () => import('@/components/Social'),
    },
  };
</script>

<style lang="scss">
  // @import "@/styles/settings.scss";

  .footer {
    display: contents;
    font-size: 18px;

    &Background {
      height: 150px;
      background: black;
      color: white;
      @include tablet {
        height: 260px;
      }
    }
    &Container {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include tablet {
        flex-direction: column;
        justify-content: center;

        @include gap(40px, col);
      }
    }
    &-privacyLink {
      width: 160px;
      text-align: right;
      @include tablet {
        text-align: center;
      }
    }
    &Social {
      display: flex;
      align-items: center;
      @include gap(40px, row);
    }

    a {
      transition: 0.2s color;
    }
    a:hover {
      color: #cccccc;
    }

    a:active {
      color: #939393;
    }
  }

  .singlePage + .footer {
    @include tablet {
      .footerBackground {
        height: 320px;
      }
      .footer-privacyLink {
        margin-bottom: 60px;
      }
    }
  }
</style>
