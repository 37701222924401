<template lang="pug">
#app
  .layout
    Header(v-if='!$route.meta.hideNavigation')
    router-view
    Footer(v-if='!$route.meta.hideNavigation')
    IntroPopup(v-if='this.$store.getters.introPopupOpen')
  SvgFooter
</template>

<script>
  import Header from '@/components/Header.vue';
  import Footer from '@/components/Footer.vue';
  import SvgFooter from '@/components/SvgFooter';
  import IntroPopup from '@/components/IntroPopup.vue'

  export default {
    components: {
      Header,
      Footer,
      SvgFooter,
      IntroPopup,
    },
    mounted() {
      this.$router.afterEach((to, from) => {
        const fbRoot = document.querySelector('#fb-root');
        if (fbRoot && (to.name == 'MapPage' || to.name == 'SuccessPage')) {
          fbRoot.style.display = 'none';
        } else {
          fbRoot.style.display = 'block';
        }
        const fromSinglePageToSinglePage = from.name == 'SinglePage' && to.name == 'SinglePage';
        if (from.name == to.name && !fromSinglePageToSinglePage) {
          return;
        }
        window.scrollTo(0, 0);
        this.$store.commit('pageDisload');
        if (!this.$store.getters.locations[0]) {
          this.$store.dispatch('getLocations');
        }
      });
    },
  };
</script>

<style lang="scss">
  @import 'scss/reboot';
  @import 'scss/base';
  @import 'scss/common';
  @import 'scss/fonts';
  @import 'scss/layout';
</style>
