import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/HomePage'),
    meta: {
      title: 'Home Page - Example App',
    },
  },
  {
    path: '/listings/:for?',
    name: 'ListingsPage',
    component: () => import('../views/ListingsPage'),
  },
  {
    path: '/listing/:id',
    name: 'SinglePage',
    component: () => import('../views/SinglePage'),
  },
  {
    path: '/sold',
    name: 'SoldPage',
    component: () => import('../views/SoldPage'),
  },
  {
    path: '/map',
    name: 'MapPage',
    component: () => import('../views/MapPage'),
    meta: { hideNavigation: true },
  },
  {
    path: '/success',
    name: 'SuccessPage',
    component: () => import('../views/AlertPage'),
    meta: { hideNavigation: true, type: 'success' },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPage',
    component: () => import('../views/PrivacyPage'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/AlertPage'),
    meta: { hideNavigation: true, type: '404' },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    }
  },
});

export default router;
