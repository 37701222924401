import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    desktop: window.matchMedia('(min-width: 769px)').matches,
    desktop2: window.matchMedia('(min-width: 1100px)').matches,
    contactBlockOffset: null,
    pageIsLoaded: false,
    locations: [],
    introPopupOpen: !Cookies.get('introSeen') || false,
  },
  getters: {
    pageIsLoaded(state) {
      return state.pageIsLoaded;
    },
    desktop(state) {
      return state.desktop;
    },
    desktop2(state) {
      return state.desktop2;
    },
    locations(state) {
      return state.locations;
    },
    introPopupOpen(state) {
      return state.introPopupOpen;
    },
  },

  mutations: {
    pageLoaded() {
      this.state.pageIsLoaded = true;
      document.querySelector('.preloader').classList.add('_disabled');
      let event = new Event('render-ready');
      window.document.dispatchEvent(event);
    },
    pageDisload() {
      this.state.pageIsLoaded = false;
      document.querySelector('.preloader').classList.remove('_disabled');
    },
    setLocations({}, locations) {
      console.log('locations', locations);
      this.state.locations = locations;
    },
    toggleIntroPopupOpen({}, isOpen) {
      this.state.introPopupOpen = isOpen;
    },
  },

  actions: {
    async getListings({ commit }, params) {
      let queryParam = '';
      let queryArray = [];
      let complexParams = [];

      if (params.location) {
        queryArray.push(`location_eq=${params.location}`);
      }
      if (params.baths) {
        queryArray.push(`baths_gte=${params.baths}`);
      }
      if (params.guests) {
        queryArray.push(`maxGroup_gte=${params.guests}`);
      }
      if (params.beds) {
        queryArray.push(`beds_gte=${params.beds}`);
      }
      if (params.purpose) {
        queryArray.push(`for_eq=${params.purpose}`);
      }
      if (params.address) {
        queryArray.push(`address_contains=${params.address}`);
      }

      if (params.priceFrom && params.priceTo) {
        complexParams.push({ priceValue_gte: params.priceFrom });
        complexParams.push({ priceValue_lte: params.priceTo });
      }

      if (params.sqftFrom && params.sqftTo) {
        complexParams.push({ sqft_gte: params.sqftFrom });
        complexParams.push({ sqft_lte: params.sqftTo });
      }

      if (params.dateFrom && params.dateTo) {
        complexParams.push({
          _or: [
            [{ availState_eq: true }, { availCondition_eq: 'always' }], // Всегда доступен
            [
              { availState_eq: true },
              { availCondition_eq: 'between' },
              { availFrom_lte: params.dateFrom },
              { availTo_gte: params.dateTo },
            ], // Доступен с такое - то по такое -то
            [
              { availState_eq: false },
              { availCondition_eq: 'between' },
              { availTo_gte: params.dateTo },
            ], // Не доступен с такое то по такое-то
            [
              { availState_eq: false },
              { availCondition_eq: 'between' },
              { availTo_lte: params.dateFrom },
            ], // Не доступен с такое то по такое-то
          ],
        });
      }

      const complexQuery = qs.stringify({
        _where: complexParams,
      });
      queryArray.push(complexQuery);

      queryArray.push('sold_eq=false'); // исключяя проданные
      queryArray.push('_sort=id:ASC');
      queryParam = queryArray.filter(Boolean).join('&');

      try {
        const res = await axios({
          method: 'get',
          url: `/listings${queryArray[0] || queryArray[1] ? '?' + queryParam : ''}`,
        });
        //consol('res', res);
        //consol('nores', params, commit);
        return res.data;
      } catch (err) {
        //consol(err);
      }
    },
    async getListingById({ commit }, id) {
      try {
        const res = await axios({
          method: 'get',
          url: `/listings?link_eq=${id}`,
        });
        //consol('res!', res);
        //consol('nores', id, commit);
        return res.data[0];
      } catch (err) {
        //consol(err);
      }
    },
    async getTopListings({ commit }) {
      try {
        const res = await axios({
          method: 'get',
          url: `/listings/top`,
        });
        const resFirst = await axios({
          method: 'get',
          url: `/listings?link_eq=tritonluxuryvilla`,
        });
        const resSecond = await axios({
          method: 'get',
          url: `/listings?link_eq=2shores`,
        });
        const resThird = await axios({
          method: 'get',
          url: `/listings?link_eq=OspreyBeachHotel`,
        });
        const resFourth = await axios({
          method: 'get',
          url: `/listings?link_eq=TheRegentGrand701`,
        });
      
        //consol('nores', commit);
        return [...resFirst.data,...resSecond.data,...resThird.data,...resFourth.data, ...res.data.slice(0,1)];
      } catch (err) {
        //consol(err);
      }
    },
    async getSoldListings({ commit }) {
      try {
        const res = await axios({
          method: 'get',
          url: `/listings?sold_eq=true`,
        });
        //consol(res);
        //consol('nores', commit);
        return res.data;
      } catch (err) {
        //consol(err);
      }
    },
    async getLocations({ commit }) {
      try {
        const res = await axios({
          method: 'get',
          url: '/locations',
        });
        //consol(res);
        //consol('nores', commit);
        commit('setLocations', res.data);
        return res.data;
      } catch (err) {
        //consol(err);
      }
    },
    async minmax({ commit }) {
      try {
        const res = await axios({
          method: 'get',
          url: '/listings/minmax?sold_eq=false&for_eq=for_sale',
        });
        //consol(res);
        //consol('nores', commit);
        commit('pageLoaded');
        return res.data;
      } catch (err) {
        //consol(err);
      }
    },

    async sendEmail({ commit }, data) {
      try {
        const res = await axios({
          method: 'post',
          url: '/send',
          data,
        });
        //consol(res);
        //consol('nores', commit);
        return res;
      } catch (err) {
        //consol(err);
      }
    },
  },
  modules: {},
});
