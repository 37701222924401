import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker'
import router from './router';
import store from './store';
import axios from 'axios';

console.log(process.env.NODE_ENV);
Vue.prototype.$frontname =
  process.env.NODE_ENV == 'production'
    // ? 'https://agencyian.com/'
    ? 'https://ian.realestate/'
    : 'http://192.168.0.102:8080/';
// Vue.prototype.$hostname = 'https://api.agencyian.com';
Vue.prototype.$hostname = 'https://api.ian.realestate';
axios.defaults.baseURL = Vue.prototype.$hostname;
// flexGapPolyfill.process(YOUR_CSS /*, processOptions, pluginOptions */);

Vue.config.productionTip = false;

import VueProgressiveImage from 'vue-progressive-image';
Vue.use(VueProgressiveImage);

const desktop = window.matchMedia('(min-width: 769px)').matches;
// var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

function setMainScreenHeight() {
  const vh = document.documentElement.clientHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  console.log(1);
}

if (desktop) {
  window.addEventListener('resize', setMainScreenHeight);
}
setMainScreenHeight();

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number') {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

// Впиндюрить фильтр дат

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
