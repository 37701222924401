<template lang="pug">
header.header
  section.headerBackground(:class='{ _scrolled: whiteStyle }')
  section.headerContainer(:class='{ _scrolled: whiteStyle }')
    router-link(:to='{ name: "HomePage" }'): SvgComponent.header-logo(name='logo')
    nav.headerMenu
      router-link.tabletNone.headerMenu-link(:to='{ name: "ListingsPage" }') Listings
      .headerMenu-link.tabletNone(@click='scrollMeTo("#about")') About
      .headerMenu-link.tabletNone(@click='scrollMeTo("#contact")') Contact
      //- a#tomap.headerMenu-iconLink.bounceY.headerMenu-link(
      //-   :href='$route.name == "SinglePage" ? `${$frontname}map/?id=${$route.params.id}` : `${$frontname}map/`'
      //- )
      router-link#tomap.headerMenu-iconLink.bounceY.headerMenu-link(
        :to='{ name: "MapPage", query: { id: $route.name == "SinglePage" ? $route.params.id : null, location: 1} }'
      )
        SvgComponent(name='location')
        span.tabletNone Map view
      .burger(tabindex='0'): SvgComponent(name='burger')
  section.tempKludgeJalousie
  Jalousie
</template>

<script>
  export default {
    data() {
      return {
        whiteStyle: false,
      };
    },
    components: {
      SvgComponent: () => import('@/components/SvgComponent'),
      Jalousie: () => import('@/components/Jalousie'),
    },
    methods: {
      scrollTimer(callback) {
        setTimeout(callback, 150);
      },

      scrollMeTo(refName) {
        var element = document.querySelector(refName);

        function scroll() {
          var element = document.querySelector(refName);
          var top = element.offsetTop;
          window.scrollTo(0, top);
        }

        if (element) {
          scroll();
        } else {
          this.$router.push(
            { name: 'HomePage' },
            this.scrollTimer(scroll),
            this.scrollTimer(scroll),
          );
        }
      },
      checkingForStickyHeader: function () {
        // console.log('checkingForStickyHeader');
        const bg =
          document.querySelector('.homeIntro-background') ||
          document.querySelector('.singleIntro-bg');
        if (
          (this.$route.name != 'HomePage' && this.$route.name != 'SinglePage') ||
          window.scrollY > window.innerHeight - 150
        ) {
          this.whiteStyle = true;
          if (bg) {
            bg.style.opacity = '0';
            bg.style.visibility = 'hidden';
          }
        } else {
          this.whiteStyle = false;
          if (bg) {
            bg.style.opacity = '1';
            bg.style.visibility = 'visible';
          }
        }
      },
    },

    mounted() {
      this.$router.afterEach(() => {
        this.checkingForStickyHeader();
      });

      this.checkingForStickyHeader();
      window.addEventListener('scroll', this.checkingForStickyHeader);
    },
  };
</script>

<style lang="scss">
  .header {
    display: contents;
    user-select: none;
    &-logo {
      @include middle {
        transform: scale(0.75);
      }
      @include tablet {
        transform-origin: center left;
        transform: scale(0.7);
        margin-bottom: -10px;
      }
    }
    &Background {
      height: 120px;
      background: transparent;
      z-index: 3;
      position: sticky;
      top: 0;
      will-change: background-color, box-shadow;
      transition: 0.2s background-color, box-shadow;
      @include tablet {
        height: auto;
      }
      &._scrolled {
        background-color: white;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
      }
    }
    &Container {
      position: sticky;
      top: 0;
      color: white;
      z-index: 3;
      display: flex;
      align-items: center;
      transition: 0.2s color;
      will-change: color;
      &._scrolled {
        color: black;
        .headerMenu-link,
        .svg-burger {
          &:hover {
            color: $red;
          }
        }
      }
    }
    &Menu {
      display: flex;
      align-items: center;
      margin-left: auto;
      @include gap(62px, row);
      @include middle {
        @include gap(30px, row);
      }
      @include tablet {
        @include gap(20px, row);
      }

      &-iconLink {
        display: flex;
        align-items: center;
        @include gap(10px, row);
      }
    }
  }

  .burger {
    cursor: pointer;
    @include middle {
      transform-origin: center right;
      transform: scale(0.7);
    }
  }
  .headerMenu-link {
    font-size: 20px;
    @include middle {
      font-size: 16px;
    }
  }
  .headerMenu-link,
  .svg-burger {
    transition: 0.2s color;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      color: #cccccc;
    }
  }
</style>
