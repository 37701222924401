<template>
  <div class="popupBackdrop">
    <div class="popupContainer">
      <div class="popupLeft">
        <div class="headerTabs">
          <div class="tab"
          v-for="(tab, index) in tabs"
          :key="tab"
          @click="setActive(index)"
          :class="{active: active===index}"
          >
            {{tab}}
          </div>
        </div>
        <div class="tabContainer">
          <div class="tabTitle">
            {{this.tabContents[active].tabTitle}}
          </div>
          <div class="tabContent" v-html="this.tabContents[active].tabContent">
          </div>
          <div class="button"
          @click="onPopupClose()"
          >
            {{this.tabContents[active].buttonText}}
          </div>

        </div>
      </div>
      <div class="popupRight">
        <div class="imageContainer">
          <img src="@/assets/images/ian_popup.jpg" alt="">
        </div>
        <div class="close" @click="$store.commit('toggleIntroPopupOpen', false);">
          <img src="@/svg/close.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';

  export default {   
    data(){
      return {
        tabs: ['English','German','Dutch','French'],
        tabContents:[
          {tabTitle:'Real estate in the Caribbean',
           tabContent:
            `<div> <p>One of the top agents serving the Caribbean's luxury markets,Ian hurdle brings
             more than 30 years of experiencein the real estate, construction, property management,
              and hospitality industries to his role as Founder and Director of The Agency Caribbean.</p>

            <p>The Agency is a full-service, luxury real estate brokerage and lifestyle company representing
             clients worldwide in a broad spectrum of classes, including residential, new development,
              resort real estate, residential leasing and luxury vacation rentals.</p>

            <p>For more questions and a detailed overview, please get in touch with
             us at <a href="mailto:ian.hurdle@theagencyre.tc">ian.hurdle@theagencyre.tc</p></div>`,
             buttonText:'continue',},
             
          {tabTitle:'Immobilien in der Karibik',
           tabContent:
            `<div> <p>Als einer der Top-Makler für die Luxusmärkte in der Karibik, bringt Ian Hurdle
             mehr als 30 Jahre Erfahrung in den Bereichen Immobilien, Bauwesen, Immobilienverwaltung
              und Gastgewerbe zu seiner Rolle als Gründer und Direktor der The Agency Caribbean.</p>

            <p>The Agency ist ein Full-Service-Unternehmen für Luxusimmobilienvermittlung und 
            Lifestyle, das Kunden weltweit as einem breiten Spektrum vertritt, darunter Wohn-, 
            Neubauprojekte, Resort-Immobilien, Wohnvermietung und Vermietung on Luxusobjekten.</p>

            <p>Für weitere Fragen und einen detaillierten Einblick in unser Angebot wenden Sie sich
             bitte an <a href="mailto:ian.hurdle@theagencyre.tc">ian.hurdle@theagencyre.tc</a></div></p>`,
             buttonText:'weitermachen',},
          {tabTitle:'Onroerend goed in het Caribisch',
           tabContent:
            `<div><p> Als een van de topmakelaars voor de luxemarkten in het Caribisch gebied brengt
             Ian Hurdle meer dan 30 jaar ervaring in onroerend goed, bouw, vastgoedbeheer en horeca
              mee in zijn rol als oprichter en directeur van The Agency Caribbean.</p>

            <p>The Agency is een full service luxe vastgoedmakelaardij en lifestylebedrijf dat klanten
             over de hele wereld vertegenwoordigt, waaronder residentiële, nieuwbouwprojecten,
              resortvastgoed, residentiële verhuur en luxe vastgoedverhuur.</p>

            <p>Voor verdere vragen en een gedetailleerd inzicht in ons aanbod kunt u contact opnemen
             met <a href="mailto:ian.hurdle@theagencyre.tc">ian.hurdle@theagencyre.tc</a></p></div>`,
             buttonText:'doorgaan',},
          {tabTitle:`L'immobilier dans les Caraïbes`,
           tabContent:
            `<div><p> Ian Hurdle, l’un des meilleurs agents au service des marches du luxe dens les
             Caraïbes apportant plus de 30 ans d’expérience à son role de fondateur et de directeur
              The Agency Caribbean tous les secteurs de l’immobilier, te la construction, te la
               gestion immobilière et de l’hôtellerie.</p>

            <p>The Agency est une société de courtage immobilier de luxe et de style de vie offrant
             à une clientèle internationale un service complet comprenant des Résidences, des nouveaux
              développements, de l’immobilier de villégiatures, des locations Résidentielles ainsi que
               des locations te vacances de luxe.</p>

            <p>Pour plus de questions et un aperçu détaillé de notre offre, veuillez
             contacter <a href="mailto:ian.hurdle@theagencyre.tc">ian.hurdle@theagencyre.tc</a></p></div>`,
            buttonText:'continuer',},         

        ],
        active: 0,
      }
    },

    methods: {
      setActive(v){
        this.active = v;
      },
      onPopupClose(){
        this.$store.commit('toggleIntroPopupOpen', false);
        Cookies.set('introSeen', true, { expires: 7 }); // Expires in 7 days
      },
    },

    components: {
    },
  };
</script>

<style lang="scss">
  // @import "@/styles/settings.scss";

  .popupBackdrop {    
    position: fixed;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    top: 0;
    left: 0;

    .popupContainer {
      width: 1240px;
      height: 745px;
      background-color:white;
      display:grid;
      grid-template-columns: 1.5fr 1fr;


      .popupRight {
        width: 500px;
        height: 745px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .close {
          position: absolute;
          top: 30px;
          right: 30px;
          cursor: pointer;
        }

      }
      .popupLeft {
        .headerTabs {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
            .tab {
              box-sizing: border-box;
              padding: 30px;
              font-size: 20px;
              text-transform: uppercase;
              font-weight: 600;
              background: #f1f1f1;
              cursor: pointer;
              text-align: center;
              transition: 0.2s background; 

              &.active {
                color:#d41e34;
                background:white;
                transition: 0.2s background;
              }
            }
          }
        .tabContainer {
          padding: 50px;
          .tabTitle {
            font-family: Proto Grotesk Web;
            font-size: 39px;
            font-weight: 700;
            margin-bottom: 30px;
          }
          .tabContent {
            font-size: 20px;
            line-height: 32px;
            p {
              margin-bottom: 30px;
              a {
                position: relative;
                &:after {
                  position:absolute;
                  left:0;
                  bottom:0;
                  content:'';
                  width:100%;
                  border-top:1px solid black;
                }
              
            }
            }
            
          }
        }
        .button {
          width: 50%;
        }
      }
    }

    @include tablet {
      display: none;
    }
    @include phone {
      display: none;
    }
  }

</style>
